import React, {FC, useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import {Link, navigate} from 'gatsby';

import Section from '../../components/section';
import ToolTabs from '../../components/scan-tools/ToolTabs';
import Layout from '../../components/layout/layout';
import {DomainForm} from '../../components/form/domain-form';
import {Translate} from '../../components/translations';
import {PageHero} from '../../components/page-hero';
import ScanCountChecker from '../../components/scan-tools/scan-counter';
import ToolToken from '../../components/scan-tools/ToolToken';
import DomainIPCheckerTool from '../../components/scan-tools/domainIPChecker';
import Button from '../../components/button';

import {Modal} from '../../components/modal';
import {TrackToolScan} from '../../helpers/trackingEvents';
import {ScanResultsPageProps} from '../../types';

import ipLookup from '../../img/home-tools/ip-lookup.png';
import hexomaticCTA from '../../img/hexomatic-banner/hexomatic-cta.jpg';

const DomainIPExtractor: FC<ScanResultsPageProps> = props => {
  const [scanLimitReached, setScanLimitReached] = useState(false);
  const url = props['*'];
  const [defaultIP, setDefaultURL] = useState('');

  const isMobile = useMediaQuery({maxWidth: 767});

  useEffect(() => {
    if (!url) {
      (async () => {
        try {
          const res = await fetch('https://api.ipify.org?format=json');
          const {ip} = await res.json();
          setDefaultURL(ip);
        } catch (e) {}
      })();
    }
  });

  const hasDomain = url && url.length > 0;

  return (
    <Layout>
      <PageHero
        title={<Translate name={'IP lookup tool'} />}
        subtitle={'Enter the IP Address or Domain Name to get IP location details.'}
      >
        <DomainForm value={defaultIP} onSubmit={u => navigate(`/ip-lookup/${encodeURIComponent(u)}`)} />
      </PageHero>
      <Section>
        {isMobile ? (
          <>
            <div className="col-md-9 tool-detail">
              {!hasDomain && (
                <div>
                  <h6>
                    Your IP Address: <i>{defaultIP}</i>
                  </h6>
                  <br />
                </div>
              )}
              <div className="row">
                <div className="col-md-12">
                  {hasDomain && url ? (
                    <ScanCountChecker url={url} disableMonitoring>
                      <ToolToken url={url}>
                        {sessionToken => {
                          TrackToolScan('IPLookup', url);
                          return (
                            <div className="row">
                              <div className="col-md-12">
                                <DomainIPCheckerTool
                                  fullResult
                                  url={url}
                                  sessionToken={sessionToken}
                                  handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                                />
                              </div>
                            </div>
                          );
                        }}
                      </ToolToken>
                    </ScanCountChecker>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-md-4" style={{marginBottom: '20px'}}>
                          <img src={ipLookup} alt="" style={{width: '100%', maxWidth: '300px'}} />
                        </div>
                        <div className="col-md-8">
                          <p>
                            Our free IP Checker tool helps to find out the country, owner and organizational data for
                            any IPv4 or IPv6 address.
                          </p>
                        </div>
                        <div className="col-md-12">
                          <p>
                            <strong>Information you can get with the lookup IP address location tool:</strong>
                          </p>
                          <ul>
                            <li>Country</li>
                            <li>Organization Address</li>
                            <li>Organization Name</li>
                            <li>Network Name</li>
                            <li>Reverse DNS</li>
                            <li>IP Range</li>
                            <li>Open Ports</li>
                          </ul>
                          <p>
                            <strong>The IP lookup tool does not provide the following information:</strong>
                          </p>
                          <ul>
                            <li>A person's name </li>
                            <li>The exact street address</li>
                            <li>A phone number</li>
                            <li>Their email address</li>
                          </ul>
                          <p>
                            Accuracy for the US and Europe is 99% at the country level, 90% at the state or region level
                            and 81% at the city level.
                          </p>
                        </div>
                      </div>
                      <div className="row" style={{marginTop: '30px'}}>
                        <div className="col-md-6" style={{margin: 'auto'}}>
                          <img src={hexomaticCTA} alt="Hexomatic" style={{width: '100%', maxWidth: '450px'}} />
                        </div>
                        <div className="col-md-6">
                          <div className="flex-align-center">
                            <h5 className="h5-title">Automate & scale time-consuming tasks like never before with Hexomatic</h5>
                            <p>
                              Harness the internet as your own data source, build your own scraping bots and leverage
                              ready made automations to delegate time consuming tasks and scale your business.
                            </p>
                            <p>No coding or PhD in programming required.</p>
                            <div>
                              <Button
                                hexomaticCTA
                                className="monitor_btn"
                                onClick={() =>
                                  (window.location.href =
                                    'https://hxomatic.com/?utm_source=hexometer&utm_medium=tools-cta')
                                }
                              >
                                Get started in minutes
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {scanLimitReached && (
                <div className="row">
                  <div className="col-md-12 text-center">
                    <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                    <p>&nbsp;</p>
                    <Modal
                      isOpen={true}
                      modalToggle={() => {}}
                      title="LOGIN_TO_CONTINUE"
                      modalFooter={
                        <Link to="/#login" state={{goBack: true}}>
                          <button type="button" className="btn btn-secondary">
                            <Translate name="GO_TO_LOGIN_PAGE" />
                          </button>
                        </Link>
                      }
                    >
                      <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                    </Modal>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-3">
              <ToolTabs active="ip-lookup" url={url} />
            </div>
          </>
        ) : (
          <>
            <div className="col-md-3">
              <ToolTabs active="ip-lookup" url={url} />
            </div>
            <div className="col-md-9 tool-detail">
              {!hasDomain && (
                <div>
                  <h6>
                    Your IP Address: <i>{defaultIP}</i>
                  </h6>
                  <br />
                </div>
              )}
              <div className="row">
                <div className="col-md-12">
                  {hasDomain && url ? (
                    <ScanCountChecker url={url} disableMonitoring>
                      <ToolToken url={url}>
                        {sessionToken => {
                          TrackToolScan('IPLookup', url);
                          return (
                            <div className="row">
                              <div className="col-md-12">
                                <DomainIPCheckerTool
                                  fullResult
                                  url={url}
                                  sessionToken={sessionToken}
                                  handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                                />
                              </div>
                            </div>
                          );
                        }}
                      </ToolToken>
                    </ScanCountChecker>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-md-4" style={{marginBottom: '20px'}}>
                          <img src={ipLookup} alt="" style={{width: '100%', maxWidth: '300px'}} />
                        </div>
                        <div className="col-md-8">
                          <p>
                            Our free IP Checker tool helps to find out the country, owner and organizational data for
                            any IPv4 or IPv6 address.
                          </p>
                        </div>
                        <div className="col-md-12">
                          <p>
                            <strong>Information you can get with the lookup IP address location tool:</strong>
                          </p>
                          <ul>
                            <li>Country</li>
                            <li>Organization Address</li>
                            <li>Organization Name</li>
                            <li>Network Name</li>
                            <li>Reverse DNS</li>
                            <li>IP Range</li>
                            <li>Open Ports</li>
                          </ul>
                          <p>
                            <strong>The IP lookup tool does not provide the following information:</strong>
                          </p>
                          <ul>
                            <li>A person's name </li>
                            <li>The exact street address</li>
                            <li>A phone number</li>
                            <li>Their email address</li>
                          </ul>
                          <p>
                            Accuracy for the US and Europe is 99% at the country level, 90% at the state or region level
                            and 81% at the city level.
                          </p>
                        </div>
                      </div>
                      <div className="row" style={{marginTop: '30px'}}>
                        <div className="col-md-6" style={{margin: 'auto'}}>
                          <img src={hexomaticCTA} alt="Hexomatic" style={{width: '100%', maxWidth: '450px'}} />
                        </div>
                        <div className="col-md-6">
                          <div className="flex-align-center">
                            <h5 className="h5-title">Automate & scale time-consuming tasks like never before with Hexomatic</h5>
                            <p>
                              Harness the internet as your own data source, build your own scraping bots and leverage
                              ready made automations to delegate time consuming tasks and scale your business.
                            </p>
                            <p>No coding or PhD in programming required.</p>
                            <div>
                              <Button
                                hexomaticCTA
                                className="monitor_btn"
                                onClick={() =>
                                  (window.location.href =
                                    'https://hexomatic.com/?utm_source=hexometer&utm_medium=tools-cta')
                                }
                              >
                                Get started in minutes
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {scanLimitReached && (
                <div className="row">
                  <div className="col-md-12 text-center">
                    <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                    <p>&nbsp;</p>
                    <Modal
                      isOpen={true}
                      modalToggle={() => {}}
                      title="LOGIN_TO_CONTINUE"
                      modalFooter={
                        <Link to="/#login" state={{goBack: true}}>
                          <button type="button" className="btn btn-secondary">
                            <Translate name="GO_TO_LOGIN_PAGE" />
                          </button>
                        </Link>
                      }
                    >
                      <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                    </Modal>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </Section>
    </Layout>
  );
};

export default DomainIPExtractor;
